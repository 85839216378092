import React, { useEffect } from "react";
import clsx from "clsx";
// Material
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";

// Icons
import CloseIcon from "@material-ui/icons/Close";
// Controls
import IconBtn from "../../Controls/IconButton/IconButton";
// Styles
import useStyles from "./Modal.style.js";
import { Collapse } from "@material-ui/core";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const normalTransition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  const classes = useStyles();
  return (
    <MuiDialogTitle
      disableTypography
      {...other}
      classes={{
        root: classes.modalTitleRoot,
      }}
    >
      {children && (
        <Typography variant="h6" className={classes.title}>
          {children}
        </Typography>
      )}
      {onClose ? (
        <IconBtn
          aria-label="close"
          component="span"
          icon={<CloseIcon />}
          onClick={onClose}
          className={classes.closeButton}
        />
      ) : null}
    </MuiDialogTitle>
  );
};

const ModalComponent = ({
  title = null,
  enableTitle = true,
  customView = false,
  open,
  children,
  makeBackGroundBlack = false,
  handleClose = () => {},
  modelContainer = null,
  mainContainerClass = null,
  backDropFunc,
  isEditMode = false,
  isCollapse = false,
  isBodyScrollLock = false,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog
      fullScreen={fullScreen}
      TransitionComponent={isCollapse ? Transition : normalTransition}
      onClose={!isEditMode && handleClose}
      disableScrollLock={isBodyScrollLock}
      // aria-labelledby="dialog-title"
      open={open}
      classes={{
        root: makeBackGroundBlack && classes.bgBlack,
        paper: clsx(classes.paper, modelContainer),
        container: mainContainerClass,
      }}
      onBackdropClick={isEditMode && backDropFunc}
    >
      {customView === true && <React.Fragment>{children}</React.Fragment>}

      {customView === false && (
        <React.Fragment>
          {enableTitle && (
            <DialogTitle onClose={!isEditMode && handleClose}>
              {title}
            </DialogTitle>
          )}
          <DialogContent
            classes={{
              root: classes.content,
            }}
          >
            {children}
          </DialogContent>
        </React.Fragment>
      )}
    </Dialog>
  );
};

export default ModalComponent;
