import React, { useState } from "react";
import useStyles from "./BranchHotels.style";
import { Typography, useMediaQuery } from "@material-ui/core";
import clsx from "clsx";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import { CloseOutlined } from "@material-ui/icons";
import { useSelector } from "react-redux";
import { useTheme } from "@material-ui/styles";

const BranchHotels = ({ branches, handleListItemClick, setdropdownAnchor }) => {
  const classes = useStyles();
  const [searchPhrase, setSearchPhrase] = useState("");
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const { hotel } = useSelector((state) => state.hotel);

  // **Function to format address**
  const formatAddress = (branch) => {
    const address = branch?.address || [];
    if (address.length > 0) {
      return address
        .map(
          (addr) =>
            `${addr?.streetName || ""}, ${addr?.postalCode || ""}, ${
              addr?.city || ""
            }, ${addr?.country || ""}`
        )
        .join(" ");
    } else {
      return branch?.address_full || "No address available";
    }
  };

  // **Filter branches by search phrase**
  const filteredBranches = branches.filter((branch) => {
    const name = branch?.name?.toLowerCase() || "";
    const address = formatAddress(branch)?.toLowerCase();
    const searchText = searchPhrase.toLowerCase();
    return (
      name.includes(searchText) || (address && address.includes(searchText))
    );
  });

  // **Handle Search Input**
  const onSearch = (e) => {
    setSearchPhrase(e.target.value);
  };

  return (
    <div className={classes.branchContainer}>
      <Typography
        className={classes.closeBtn}
        onClick={() => setdropdownAnchor(false)}
      >
        <CloseOutlined />
      </Typography>
      <div className={classes.headerDiv}>
        <Typography className={classes.branchTitle}>Select Branches</Typography>
        <div className={clsx(classes.search, classes.grow)}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            value={searchPhrase}
            onChange={onSearch}
            placeholder="Search"
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                event.target.blur();
              }
            }}
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            inputProps={{ "aria-label": "search" }}
          />
        </div>
      </div>

      <div
        className={
          isMobileScreen ? classes.branchMobModal : classes.branchModal
        }
      >
        {filteredBranches
          ?.filter((branch) => branch?.online_key !== hotel?.online_key)
          ?.map((branch) => (
            <div
              key={branch.id} // Ensure each branch has a unique key
              className={classes.branchCard}
              onClick={() => handleListItemClick(branch)}
            >
              <Typography className={classes.branchName}>
                {branch?.name}
              </Typography>
              <Typography>{formatAddress(branch)}</Typography>
            </div>
          ))}
      </div>
    </div>
  );
};

export default BranchHotels;
