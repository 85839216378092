import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  branchContainer: {
    display: "flex",
    flexDirection: "column",
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.common?.bgVariation,
    marginLeft: theme.spacing(1),
    border: "1px solid black",
  },
  grow: {
    flexGrow: 1,
  },
  branchTitle: {
    fontWeight: 700,
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.common.themeFontColor,
  },
  headerDiv: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: theme.palette.common.themeFontColor,
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
  },
  closeBtn: {
    fontSize: "1rem",
    color: "red",
    position: "absolute",
    top: "2px",
    right: "4px",
    "&:hover": {
      cursor: "pointer",
      transform: "scale(1.02)", // Optional slight scaling effect
      transition: "transform 0.2s, background-color 0.2s", // Smooth transition
    },
  },
  branchCardDiv: {
    display: "flex",
  },
  branchModal: {
    display: "flex",
    flexWrap: "wrap",
    gap: "16px",
    paddingTop: "15px",
    maxHeight: "180px",
    overflowX: "hidden",
    overflowY: "scroll",
  },
  branchMobModal: {
    display: "flex",
    flexDirection: "column",
    // flexWrap: "wrap",
    width: "100%",
    gap: "8px",
    paddingTop: "15px",
    maxHeight: "180px",
    overflowX: "hidden",
    overflowY: "scroll",
  },

  branchCard: {
    flex: "1 1 calc(50% - 16px)",
    boxSizing: "border-box",
    border: "1px solid #000",
    padding: "16px",
    borderRadius: "8px",
    backgroundColor: theme?.palette?.card?.cardColor,
    color: theme?.palette?.card?.cardFontColor,
    "&:hover": {
      cursor: "pointer",
      backgroundColor: theme?.palette?.card?.cardColor || "#f0f0f0", // Optional hover background color
      transform: "scale(1.02)", // Optional slight scaling effect
      transition: "transform 0.2s, background-color 0.2s", // Smooth transition
    },
  },
  branchName: {
    fontWeight: 600,
  },
}));

export default useStyles;
