import * as types from "./HomeTypes";

export const getHomeRestaurant = (payload) => ({
  type: types.GET_HOME_RESTAURANT_REQUEST,
  payload,
});

export const getHomeRestaurantReceive = (payload) => ({
  type: types.GET_HOME_RESTAURANT_RECEIVE,
  payload,
});

export const clearHomeResturant = () => ({
  type: types.CLEAR_HOME_RESTURANT,
});
export const updateHomeLocation = (data) => ({
  type: types.UPDATE_HOME_LOCATION,
  payload: data,
});

export const setInitialHomeLocation = (data) => ({
  type: types.SET_INITIAL_HOME_LOCATION,
  payload: data,
});

export const setHomepageLoaded = (data) => ({
  type: types.IS_HOMEPAGE_LOADED,
  payload: data,
});
