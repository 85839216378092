import * as types from "./HomeTypes";

const INITIAL_STATE = {
  location: null,
  isHotelFetchCompleted: false,
  hotel: null,
  lat: null,
  lng: null,
  isHomePageLoaded: false,
};

// Replace with you own reducer
const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_HOME_RESTAURANT_REQUEST:
      return {
        ...state,
        location: action.payload,
        isHotelFetchCompleted: false,
        hotel: null,
      };
    case types.GET_HOME_RESTAURANT_RECEIVE:
      return {
        ...state,
        isHotelFetchCompleted: true,
        hotel: action.payload,
      };
    case types.CLEAR_HOME_RESTURANT:
      return {
        ...state,
        isHotelFetchCompleted: null,
        hotel: null,
      };

    case types.UPDATE_HOME_LOCATION:
      state.lat =
        (action.payload.geometry && action.payload.geometry.location.lat()) ||
        null;
      state.lng =
        (action.payload.geometry && action.payload.geometry.location.lng()) ||
        null;
      state.location = (action.payload && action.payload) || null;
      return state;

    case types.SET_INITIAL_HOME_LOCATION:
      state.lat = (action.payload.lat && action.payload.lat) || null;
      state.lng = (action.payload.lng && action.payload.lng) || null;
      state.location = (action.payload && action.payload) || null;
      return state;
    case types.IS_HOMEPAGE_LOADED:
      state.isHomePageLoaded = action.payload;
      return state;
    default:
      return state;
  }
};

export default reducer;
